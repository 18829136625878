import React, {useState, useCallback} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import config from "./configs/config";
// const axios = require('axios').default;

const LoginPage = (props) => {
    const {keycloakConfig, setToken, setAuthorized} = props;

    const [usernameValue, setUsernameValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');

    const login = useCallback(
        () => {
            const data = `grant_type=${keycloakConfig.grant_type}&client_id=${keycloakConfig.client_id}&username=${usernameValue}&password=${passwordValue}&client_secret=${keycloakConfig.client_secret}`;

            // console.log(data)

            const xhr = new XMLHttpRequest();
            xhr.withCredentials = true;

            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    try {
                        if (xhr.status === 200) {
                            const jwt = JSON.parse(xhr.response)
                            setToken(jwt.access_token);
                            setAuthorized(true);
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            });

            xhr.open("POST", "https://keycloak.biosort.io/auth/realms/RVS/protocol/openid-connect/token");
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

            xhr.send(data);


        }, [keycloakConfig, usernameValue, passwordValue, setToken, setAuthorized]
    );

    // const login = useCallback(
    //   () => {
    //     axios.post(keycloakConfig.token_url, {
    //         grant_type: keycloakConfig.grant_type,
    //         client_id: keycloakConfig.client_id,
    //         client_secret: keycloakConfig.client_secret,
    //         username: usernameValue,
    //         password: passwordValue,
    //     },
    //         {
    //             headers: {
    //                 "Content-Type": "application/x-www-form-urlencoded",
    //                 "Accept": "*/*"
    //             }
    //         })
    //     .then(response => {
    //         if (response.status === 200){
    //             setToken(response.data.access_token);
    //             setAuthorized(true);
    //         }
    //     })
    //     .catch(error => {
    //         console.log(error)
    //     })
    //   },
    //   [keycloakConfig, usernameValue, passwordValue, setToken, setAuthorized],
    // );

    return (
        <div>
            <TextField
                required id="standard-required"
                label="Username"
                value={usernameValue}
                onChange={(e) => setUsernameValue(e.target.value)}
            />
            <TextField
                id="standard-password-input"
                label="Password"
                type="password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
            />
            <Button
                variant="contained"
                onClick={login}
            >
                Login
            </Button>
        </div>
    );

};

export {LoginPage};
