import React from 'react';
import dashjs from 'dashjs';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
const axios = require('axios').default;


export class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            djsmp1: undefined,
            switch_value: true,
            barge_pen_dict: {},
            pen_camera_dict: {},
            camera_date_dict: {},
            selected_barge: null,
            selected_pen: null,
            selected_camera: null,
            selected_date: null,
            selected_start_time: null,
            live_streams: null,
            static_streams: null,
        };
    }

    componentDidMount = () => {
        this.fetchLiveComboBoxes()
    }

    dictSetAddElem = (dict, key, val) => {
        if (dict[key] === undefined) {
            dict[key] = new Set()
        }
        dict[key].add(val)
    }

    getDateFromFolderName = (foldername) => {
        return foldername.split('_')[0]
    }

    parseAvailableStreams = (streams) => {
        let barge_pen_dict = {}
        let pen_camera_dict = {}
        let camera_date_dict = {}
        for (let item of streams) {
            let item_s = item.split('/')
            if (item_s.length !== 5) {
                continue
            }
            this.dictSetAddElem(barge_pen_dict, item_s[1], item_s[2])
            this.dictSetAddElem(pen_camera_dict, item_s[2], item_s[3])
            this.dictSetAddElem(camera_date_dict, item_s[3], this.getDateFromFolderName(item_s[4]))
        }
        this.setState({ barge_pen_dict: barge_pen_dict, pen_camera_dict: pen_camera_dict, camera_date_dict: camera_date_dict })
    }

    getBargesForComboBox = () => {
        let barges = Object.keys(this.state.barge_pen_dict)
        if (barges === undefined) {
            return []
        }
        return barges
    }

    getPensForComboBox = () => {
        if (this.state.selected_barge == null) {
            return []
        }
        let pens = this.state.barge_pen_dict[this.state.selected_barge]
        if (pens === undefined) {
            return []
        }
        return [...pens]
    }

    onBargeComboBoxChange = (event, val) => {
        this.setState({ selected_barge: val, selected_pen: null, selected_camera: null, selected_date: null, selected_start_time: null })
    }

    onPenComboBoxChange = (event, val) => {
        this.setState({ selected_pen: val, selected_camera: null, selected_date: null, selected_start_time: null })
    }

    onCameraComboBoxChange = (event, val) => {
        this.setState({ selected_camera: val, selected_date: null, selected_start_time: null })
    }

    onDateComboBoxChange = (event, val) => {
        this.setState({ selected_date: val, selected_start_time: null })
    }

    getCamerasForComboBox = () => {
        if (this.state.selected_pen == null) {
            return []
        }
        let cams = this.state.pen_camera_dict[this.state.selected_pen]
        if (cams === undefined) {
            return []
        }
        return [...cams]
    }

    getDatesForComboBox = () => {
        if (this.state.selected_camera == null) {
            return []
        }
        let dates = this.state.camera_date_dict[this.state.selected_camera]
        if (dates === undefined) {
            return []
        }
        return [...dates]
    }

    reset = () => {
        this.onBargeComboBoxChange(null, null)
    }

    onPlayButtonClicked = () => {
        if (this.state.djsmp1 !== undefined) {
            this.state.djsmp1.reset()
        }
        if (this.state.switch_value) {
            this.playLive()
        } else {
            this.playStatic()
        }
    }

    playStatic = () => {
        let url = this.props.server_url + '/at_time/' + this.state.selected_barge + '/' + this.state.selected_pen +
            '/' + this.state.selected_camera + '/' + this.state.selected_date + '_' + this.state.selected_start_time//moment(new Date(this.state.selected_start_time)).format('HH')
        let djs = dashjs.MediaPlayer().create()
        this.addHeadersToVideoPlayer(djs);
        this.addEventsToVideoPlayer(djs)
        this.setState({ djsmp1: djs });
        djs.initialize(this.player, url, true);
    }

    playLive = () => {
        let path = '/' + this.state.selected_barge + '/' + this.state.selected_pen +
            '/' + this.state.selected_camera + '/' + moment().format('YYYY-MM-DD')

        let url = this.props.server_url + '/live' + this.state.live_streams[path] + '/manifest.mpd'
        let djs = dashjs.MediaPlayer().create()
        this.addHeadersToVideoPlayer(djs);
        this.addEventsToVideoPlayer(djs)
        this.setState({ djsmp1: djs });
        djs.initialize(this.player, url, true);
    }

    httpGet = (url, callback) => {

        const config = {
            headers: { Authorization: `Bearer ${this.props.token}` }
        };

        axios.get(url, config)
            .then(response => {
                callback(response.data)
            })
            .catch(error => {
                console.log(error)
            })
            .then(() => {
                // TODO
            });
    }

    fetchLiveComboBoxes = () => {
        this.httpGet(this.props.server_url + '/live_streams', (data) => {
            this.setState({ live_streams: data })
            this.parseAvailableStreams(Object.keys(data))
        });
    }

    fetchStaticComboBoxes = () => {
        this.httpGet(this.props.server_url + '/static_streams', (data) => {
            this.parseAvailableStreams(data)
        });
    }

    onSwitchChanged = (event, value) => {
        this.setState({ switch_value: value })
        this.reset()
        if (value) {
            this.fetchLiveComboBoxes()
        } else {
            this.fetchStaticComboBoxes()
        }
    }

    fixLivestream = (retry = 0) => {
        this.httpGet(this.props.server_url + '/live_streams', (data) => {
            this.setState({ live_streams: data })
            this.parseAvailableStreams(Object.keys(data))
            let path = '/' + this.state.selected_barge + '/' + this.state.selected_pen +
                '/' + this.state.selected_camera + '/' + moment().format('YYYY-MM-DD')

            if (data[path] === undefined) {
                if (retry === 5) {
                    console.log("It seems like this livestream is over")
                    return
                }
                setTimeout(() => {
                    this.fixLivestream(++retry)
                }, 5000);
            } else {
                let url = this.props.server_url + '/live' + data[path] + '/manifest.mpd'
                this.state.djsmp1.reset()
                let djs = dashjs.MediaPlayer().create()
                this.addHeadersToVideoPlayer(djs);
                this.addEventsToVideoPlayer(djs)
                this.setState({ djsmp1: djs });
                djs.initialize(this.player, url, true);
            }
        });
    }

    addHeadersToVideoPlayer = (player) => {
        player.extend("RequestModifier", () => {
                return {
                    modifyRequestHeader: xhr => {
                        xhr.setRequestHeader("Authorization", `Bearer ${this.props.token}`);
                        return xhr;
                    }
                };
            },
            true
        );
    }

    addEventsToVideoPlayer = (player) => {
        player.on('error', (e) => {
            if (e.error.code === 27) {
                console.log('Trying to fix livestream')
                this.fixLivestream()
            }
        });
    }

    onRefreshButtonClicked = () => {

        this.reset()
        if (this.state.switch_value) {
            this.fetchLiveComboBoxes()
        } else {
            this.fetchStaticComboBoxes()
        }
    }

    render() {
        return <div>
            <video
                ref={player => (this.player = player)}
                controls={true}//={!this.state.switch_value}
                muted={true}
            />

            <FormControlLabel
                control={
                    <Switch checked={this.state.switch_value}
                        onChange={this.onSwitchChanged}
                        name="checkedA"
                    />
                }
                label="Live"
            />

            <Autocomplete
                id="combo-barge"
                options={this.getBargesForComboBox()}
                getOptionLabel={(option) => option}
                onChange={this.onBargeComboBoxChange}
                value={this.state.selected_barge}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Barge ID" variant="outlined" />}
            />

            {this.state.selected_barge &&
                <Autocomplete
                    id="combo-pen"
                    options={this.getPensForComboBox()}
                    getOptionLabel={(option) => option}
                    onChange={this.onPenComboBoxChange}
                    value={this.state.selected_pen}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Pen ID" variant="outlined" />}
                />
            }

            {this.state.selected_pen &&
                <Autocomplete
                    id="combo-camera"
                    options={this.getCamerasForComboBox()}
                    getOptionLabel={(option) => option}
                    onChange={this.onCameraComboBoxChange}
                    value={this.state.selected_camera}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Camera ID" variant="outlined" />}
                />
            }

            {this.state.selected_camera && !this.state.switch_value &&
                <Autocomplete
                    id="combo-date"
                    options={this.getDatesForComboBox()}
                    getOptionLabel={(option) => option}
                    onChange={this.onDateComboBoxChange}
                    value={this.state.selected_date}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Select date" variant="outlined" />}
                />
            }

            {this.state.selected_date && !this.state.switch_value &&
                <Autocomplete
                    id="combo-time"
                    options={time_options}
                    getOptionLabel={(option) => option}
                    onChange={(event, val) => this.setState({ selected_start_time: val })}
                    value={this.state.selected_start_time}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Hour segment" variant="outlined" />}
                />
            }

            {(this.state.selected_start_time || (this.state.switch_value && this.state.selected_camera)) &&
                <Button onClick={this.onPlayButtonClicked} variant="contained" color="primary">
                    Play
            </Button>
            }

            {this.state.switch_value &&
                <Button onClick={this.onRefreshButtonClicked} variant="contained" color="primary">
                    Refresh stream info
            </Button>
            }
        </div>
    }
}

const time_options = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']

export default VideoPlayer;
