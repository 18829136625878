let config = {
  server_url: "https://staging-rvs.biosort.io",
  keycloak: {
    token_url:
      "https://keycloak.biosort.io/auth/realms/RVS/protocol/openid-connect/token",
    client_id: "test-cloud",
    client_secret: "f1a7dc89-470e-4539-8aed-464b0caaa1e6",
    grant_type: "password",
  },
};

export default config;
