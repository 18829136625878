import React, { useState } from "react";
import "./App.css";
import { VideoPlayer } from "./VideoPlayer";
import { LoginPage } from "./LoginPage";
import config from "./configs/config";

const { server_url, keycloak } = config;

function App() {
  const [authorized, setAuthorized] = useState(false);
  const [token, setToken] = useState("");

  return (
    <div>
      {authorized && <VideoPlayer server_url={server_url} token={token} />}
      {!authorized && (
        <LoginPage
          server_url={server_url}
          keycloakConfig={keycloak}
          setToken={setToken}
          setAuthorized={setAuthorized}
        />
      )}
    </div>
  );
}

export default App;
